import React from "react";
import { Link } from "gatsby";
import { Layout, Menu } from "antd";
import { BookOutlined } from "@ant-design/icons";

const { Sider } = Layout;

export default class AboutSider extends React.Component {
  render() {
    return (
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <Link to={`/about/`}>
          <h2 style={{ textAlign: "center", marginTop: 20 }}>
            <BookOutlined /> About
          </h2>
        </Link>
        <div>
          <Menu theme="light">
            <Menu.Item key="1">
              <Link to="/about-content/about-content">About DayViewer</Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to="/about-content/privacy">Privacy Policy</Link>
            </Menu.Item>
            <Menu.Item key="3">
              <Link to="/about-content/terms">Terms of Use</Link>
            </Menu.Item>
          </Menu>
        </div>
      </Sider>
    );
  }
}
